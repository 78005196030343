import React from "react";
import "./../global.css";
import { ThemeProvider } from "./ThemeContext";
import { Layout } from "./Layout";

export function App({ children }: { children: React.ReactNode }) {
  return (
    <ThemeProvider>
      <Layout>{children}</Layout>
    </ThemeProvider>
  );
}

export function wrapWithAppLayout(
  element: React.ReactElement<any, string | React.JSXElementConstructor<any>>
) {
  const newElement = React.cloneElement(
    element, // I18nextProvider
    element.props,
    element.props.children
      ? React.cloneElement(
          element.props.children, // I18nextContext.Provider
          element.props.children.props ? element.props?.children.props : {},
          React.createElement(
            App,
            undefined,
            element.props.children?.props?.children ? element.props.children.props.children : {}
          )
        )
      : []
  );

  return newElement;
}
