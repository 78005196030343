exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-en-404-tsx": () => import("./../../../src/pages/en/404.tsx" /* webpackChunkName: "component---src-pages-en-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-sv-404-tsx": () => import("./../../../src/pages/sv/404.tsx" /* webpackChunkName: "component---src-pages-sv-404-tsx" */),
  "component---src-templates-administration-tsx": () => import("./../../../src/templates/Administration.tsx" /* webpackChunkName: "component---src-templates-administration-tsx" */),
  "component---src-templates-blog-list-tsx": () => import("./../../../src/templates/BlogList.tsx" /* webpackChunkName: "component---src-templates-blog-list-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/Blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-cms-page-tsx": () => import("./../../../src/templates/CmsPage.tsx" /* webpackChunkName: "component---src-templates-cms-page-tsx" */),
  "component---src-templates-magazine-tsx": () => import("./../../../src/templates/Magazine.tsx" /* webpackChunkName: "component---src-templates-magazine-tsx" */),
  "component---src-templates-member-application-tsx": () => import("./../../../src/templates/MemberApplication.tsx" /* webpackChunkName: "component---src-templates-member-application-tsx" */),
  "component---src-templates-merit-application-tsx": () => import("./../../../src/templates/MeritApplication.tsx" /* webpackChunkName: "component---src-templates-merit-application-tsx" */),
  "component---src-templates-register-statement-tsx": () => import("./../../../src/templates/RegisterStatement.tsx" /* webpackChunkName: "component---src-templates-register-statement-tsx" */)
}

