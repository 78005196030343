import { useStaticQuery, graphql } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";

export const useNavigation = () => {
  const { language } = useI18next();
  const { allStrapiNavigationPluginMain: allNavigations } =
    useStaticQuery(graphql`
      {
        allStrapiNavigationPluginMain {
          nodes {
            id
            externalPath
            locale
            menuAttached
            order
            path
            title
            type
            uiRouterKey
            contentType
            related {
              id
            }
            childItems {
              id
              externalPath
              locale
              menuAttached
              order
              path
              title
              type
              uiRouterKey
              contentType
              related {
                id
              }
            }
          }
        }
      }
    `);

  // Filter by current language
  const navigationsByLanguage = allNavigations.nodes.filter(
    (nav: any) => nav.locale === language
  );
  // Sort by order
  const sorted = navigationsByLanguage.sort(
    (a: any, b: any) => a.order - b.order
  );
  return sorted;
};
