export const languageCodes = ["fi", "en", "sv"];

export const languages = [
  { code: "fi", name: "Suomi" },
  { code: "en", name: "English" },
  { code: "sv", name: "Svenska" },
];

export const defaultLanguage = "fi";

export type Locales = {
  locales: {
    edges: [
      node: {
        data: string;
        language: string;
        ns: string;
      }
    ]
  }
}