import React, { useEffect, useState } from "react";
import { useI18next } from "gatsby-plugin-react-i18next";
import { CheckIcon } from "@heroicons/react/20/solid";
import { GB, FI, SE } from "country-flag-icons/react/3x2";
import { useMediaQuery } from "react-responsive";

import { classNames } from "./utils";
import { Spinner } from "./Spinner";
import { languages as i18nLanguages } from "../i18n";

type Language = {
  code: string;
  name: string;
  flag?: JSX.Element;
};

export const LanguageSelector = (): JSX.Element => {
  const { i18n, originalPath, changeLanguage } = useI18next();
  const [languages, setLanguages] = useState<Language[]>();
  const [currentLanguage, setCurrentLanguage] = useState<Language>();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    const langs: Language[] = i18nLanguages.map((lng: Language) => {
      switch (lng.code) {
        case "fi":
          return {
            ...lng,
            flag: <FI title={lng.name} className="h-6 w-6 flex-shrink-0" />,
          };
        case "sv":
          return {
            ...lng,
            flag: <SE title={lng.name} className="h-6 w-6 flex-shrink-0" />,
          };
        case "en":
          return {
            ...lng,
            flag: <GB title={lng.name} className="h-6 w-6 flex-shrink-0" />,
          };
        default:
          return { ...lng };
      }
    });

    setLanguages(langs);
    const current = langs.find(
      (language: Language) => language.code === i18n.resolvedLanguage
    );
    setCurrentLanguage(current);
  }, []);

  const onClickLanguage = (lng: Language) => {
    if (isMobile) {
      const element = document.getElementById("hs-hamburger-toggle");
      if (element) {
        element.click();
      }
    }

    if (currentLanguage?.code === lng.code) {
      return;
    }

    setCurrentLanguage(lng);
    changeLanguage(lng.code, originalPath);
  };

  if (languages && currentLanguage) {
    return (
      <div className="hs-dropdown [--strategy:static] md:[--strategy:fixed] [--adaptive:none] md:[--trigger:hover]">
        <button
          type="button"
          className="flex items-center gap-x-2 py-2 px-2 mx-1 rounded-md font-medium text-slate-800 hover:bg-gray-200 md:focus:ring-2 md:focus:ring-blue-500"
        >
          <span className="flex items-center">
            {currentLanguage.flag}
            <span className="ml-3 sm:block md:hidden lg:hidden xl:block truncate">
              {currentLanguage.name}
            </span>
          </span>
          <svg
            className="ml-2 w-2.5 h-2.5 text-slate-600"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
            ></path>
          </svg>
        </button>

        <div className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] md:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 md:w-48 hidden z-10 bg-white md:shadow-md rounded-lg p-2 md:dark:border dark:md:border-slate-700 dark:divide-slate-700 before:absolute top-full md:border before:-top-5 before:left-0 before:w-full before:h-5">
          {languages.map((lng: Language) => (
            <a
              className={classNames(
                currentLanguage === lng
                  ? "text-sky-600"
                  : "text-white hover:text-gray-200",
                "flex items-center gap-x-2 py-2 px-2 mx-1 rounded-md text-base hover:bg-gray-200 md:focus:ring-2 md:focus:ring-blue-500"
              )}
              href="#"
              onClick={() => onClickLanguage(lng)}
              key={lng.code}
            >
              <>
                <div className="flex items-center">
                  <span className="pr-4">
                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                  </span>

                  {lng.flag}
                  <span
                    className={classNames(
                      currentLanguage === lng ? "font-semibold" : "font-normal",
                      "ml-3 block truncate text-slate-800"
                    )}
                  >
                    {lng.name}
                  </span>
                </div>
              </>
            </a>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div className="md:py-4">
        <Spinner text={`Loading...`} />
      </div>
    );
  }
};
