import React from "react";
import { useI18next, Link } from "gatsby-plugin-react-i18next";

const social = [
  {
    name: "Facebook",
    href: "https://www.facebook.com/Puhallinmusiikki",
    icon: (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    name: "Instagram",
    href: "https://www.instagram.com/puhallinmusiikki",
    icon: (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    name: "X",
    href: "https://x.com/puhallinmus",
    icon: (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        strokeWidth="1.5"
        stroke="currentColor"
        {...props}
      >
        <path
          fillRule="evenodd"
          d="M 13.900398,10.468667 21.345848,2 H 19.581514 L 13.116631,9.353167 7.9531482,2 H 1.9976815 L 9.805881,13.119333 1.9976815,22 H 3.7621148 L 10.589198,14.23475 16.042214,22 h 5.955467 L 13.899965,10.468667 Z m -2.416634,2.748666 -0.791132,-1.10725 -6.2947672,-8.8104163 h 2.7100667 l 5.0799505,7.1102503 0.791133,1.10725 6.603334,9.24225 H 16.872281 L 11.483764,13.21775 Z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
];

export const Footer = () => {
  const currentYear = new Date().getFullYear();
  const { t, language } = useI18next();

  return (
    <footer className="bg-white" aria-labelledby="footer-heading">
      <div className="mx-auto px-6 lg:px-8 3xl:px-72">
        <div className="border-t border-gray-900/10 py-2 md:flex md:items-center md:justify-between">
          <div className="py-4 text-base leading-5 text-gray-500 md:order-1 md:mt-0">
            &copy; {currentYear} Suomen Puhallinorkesteriliitto
          </div>
          <div className="flex text-base leading-5 space-x-6 md:order-2">
            <Link
              to="/register-statement/"
              language={language}
              target="_self"
              className="text-gray-400 hover:text-gray-500"
            >
              {t("footer.registerStatement")}
            </Link>
            {social.map((item) => (
              <a
                key={item.name}
                href={item.href}
                target="_blank"
                className="text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
