import React from "react";

export const Spinner = ({text}: {text?: string}) => {
  return (
    <div className="flex items-center">
      <div className="w-7 h-7 rounded-full animate-spin border-y-2 border-solid border-blue-500 border-t-transparent shadow-md" />
      {text && <span className="pl-3">{text}</span>}
    </div>
  );
};
