import React from "react";

import { COLOR_MODE_KEY, INITIAL_COLOR_MODE_CSS_PROP } from "../constants";

type ThemeProps = {
  colorMode: string;
  setColorMode: (newValue: string) => void;
};

const ThemeContext = React.createContext<ThemeProps | undefined>(undefined);

const ThemeProvider = ({ children }: { children: React.ReactNode }): any => {
  const [colorMode, rawSetColorMode] = React.useState<string>("dark");

  React.useEffect(() => {
    const root = window.document.documentElement;

    // Because colors matter so much for the initial page view, we're
    // doing a lot of the work in gatsby-ssr. That way it can happen before
    // the React component tree mounts.
    const initialColorValue = root.style.getPropertyValue(
      INITIAL_COLOR_MODE_CSS_PROP
    );

    rawSetColorMode(initialColorValue);
  }, []);

  const contextValue = React.useMemo(() => {
    function setColorMode(newValue: string) {
      const root = window.document.documentElement;
      localStorage.setItem(COLOR_MODE_KEY, newValue);
      root.setAttribute("data-theme", newValue);
      root.setAttribute("style", `color-scheme: ${newValue}`);
      rawSetColorMode(newValue);
    }

    return {
      colorMode,
      setColorMode,
    };
  }, [colorMode, rawSetColorMode]);

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};

function useTheme() {
  const context = React.useContext(ThemeContext);
  if (context === undefined) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
}

export { ThemeProvider, useTheme };
